import { KitProductModel } from "../../Api/Models/product.model";
import { StockModel } from "../../Api/Models/stock.model";
import exchangeIcon from "../../Assets/gfx/icons/exchange.svg";
import { t } from "i18next";

interface ExchangeMissingProps {
  missingProducts: KitProductModel[];
  stocks: StockModel[];
  setMissingProducts: CallableFunction;
}

const ExchangeMissing: React.FunctionComponent<ExchangeMissingProps> = ({
  missingProducts,
  stocks,
  setMissingProducts,
}) => {
  const stockAvailable = (
    productId: number,
    variationId: number | null,
    quantity: number
  ): boolean => {
    let productStocks = stocks.filter((stock) => stock.productId === productId);

    if (variationId) {
      productStocks = productStocks.filter(
        (stock) => stock.variationId === variationId
      );
    }

    return productStocks.some((stock) => stock.quantity >= quantity);
  };

  return (
    <>
      {missingProducts
        .filter((x, index, array) => {
          if (!x.optionalGroupId) {
            return true;
          }

          if (
            array.some(
              (p) => p.optionalGroupId === x.optionalGroupId && p.selected
            )
          ) {
            return x.selected;
          }

          return (
            !x.optionalGroupId ||
            array.findIndex(
              (group) => group.optionalGroupId === x.optionalGroupId
            ) === index
          );
        })
        .map((kitProduct) => {
          if (
            kitProduct.optionalGroupId &&
            !missingProducts.some(
              (x) =>
                x.optionalGroupId === kitProduct.optionalGroupId && x.selected
            )
          ) {
            return (
              <div className="exchange-list__item" key={kitProduct.id}>
                <div className="gap-4px nav-card mb-4">
                  <img src={kitProduct.image} alt="package name" />
                  <p>
                    <strong>
                      <select
                        className={
                          !kitProduct.checked ? "text-gray" : "text-blue"
                        }
                        onChange={(e) => {
                          setMissingProducts([
                            ...missingProducts.map((item) => {
                              if (
                                item.optionalGroupId ===
                                kitProduct.optionalGroupId
                              ) {
                                item.selected = false;
                              }

                              if (item.id === parseInt(e.target.value)) {
                                item.selected = true;
                                if (item.variations.length > 0) {
                                  item.selectedVariationId =
                                    item.variations.find((x) =>
                                      stockAvailable(
                                        item.productId,
                                        x.id,
                                        item.quantity
                                      )
                                    )?.id ?? null;
                                }
                              }

                              return item;
                            }),
                          ]);
                        }}
                      >
                        <option>- Please Select -</option>
                        {missingProducts
                          .filter(
                            (x) =>
                              x.optionalGroupId === kitProduct.optionalGroupId
                          )
                          .filter((x) =>
                            stockAvailable(x.productId, null, x.quantity)
                          )
                          .map((groupProduct) => (
                            <option
                              key={groupProduct.id}
                              value={groupProduct.id}
                            >
                              {groupProduct.name}
                            </option>
                          ))}
                      </select>
                    </strong>
                  </p>
                </div>
                <span>
                  <div className={"status close"}></div>
                </span>
              </div>
            );
          }

          return (
            <div className="exchange-list__item" key={kitProduct.id}>
              <div className="gap-4px nav-card mb-4">
                <img src={kitProduct.image} alt="package name" />
                <p>
                  <strong>
                    {kitProduct.name}

                    {kitProduct.variations.length > 0 ? (
                      <>
                        <span className="text-blue">&nbsp;-&nbsp;</span>
                        <select
                          className={
                            !kitProduct.checked ? "text-gray" : "text-blue"
                          }
                          defaultValue={undefined}
                          disabled={!kitProduct.checked}
                          onChange={(e) => {
                            setMissingProducts([
                              ...missingProducts.map((item) => {
                                if (item.id === kitProduct.id) {
                                  item.selectedVariationId = parseInt(
                                    e.target.value
                                  );
                                }
                                return item;
                              }),
                            ]);
                          }}
                        >
                          <option value={-1} disabled={kitProduct.checked}>
                            -
                          </option>

                          {kitProduct.variations
                            .filter((x) =>
                              stockAvailable(
                                kitProduct.productId,
                                x.id,
                                kitProduct.quantity
                              )
                            )
                            .map((x) => (
                              <option value={x.id} key={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </select>
                      </>
                    ) : null}
                  </strong>
                  {!stockAvailable(
                    kitProduct.productId,
                    null,
                    kitProduct.quantity
                  ) ? (
                    <>
                      <br />
                      <span className="text-red">{t("Out of stock")}</span>
                    </>
                  ) : null}
                  {kitProduct.optionalGroupId ? (
                    <>
                      <br />
                      <span
                        className="text-blue"
                        onClick={() => {
                          setMissingProducts([
                            ...missingProducts.map((item) => {
                              if (
                                item.optionalGroupId ===
                                kitProduct.optionalGroupId
                              ) {
                                item.selected = false;
                              }
                              return item;
                            }),
                          ]);
                        }}
                      >
                        {t("<< Select an other product")}
                      </span>
                    </>
                  ) : null}
                </p>
              </div>
              <span>
                <div className={"status open"}>
                  {!stockAvailable(
                    kitProduct.productId,
                    null,
                    kitProduct.quantity
                  ) ? (
                    <img
                      src={exchangeIcon}
                      alt="Exchange img"
                      className="disabled"
                    />
                  ) : (
                    <button
                      type="button"
                      className={
                        kitProduct.checked
                          ? "button-product-status-returned"
                          : "button-product-status-default"
                      }
                      onClick={() => {
                        setMissingProducts([
                          ...missingProducts.map((item) => {
                            if (item.id === kitProduct.id) {
                              item.checked = !item.checked;
                            }
                            return item;
                          }),
                        ]);
                      }}
                    >
                      <i
                        className={
                          "icon-approve " +
                          (!kitProduct.checked ? "text-green" : "")
                        }
                      ></i>
                    </button>
                  )}
                </div>
              </span>
            </div>
          );
        })}
    </>
  );
};

export default ExchangeMissing;
