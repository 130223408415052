import { useOutletContext } from "react-router-dom";
import checkIcon from "../../Assets/gfx/icons/check.svg";
import exchangeIcon from "../../Assets/gfx/icons/exchange.svg";
import { RiderModel, RiderProductModel } from "../../Api/Models/rider.model";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";
import React, { useEffect, useState } from "react";
import { RiderProfileLayoutContextInterface } from "../../Pages/Rider/RiderProfileLayout";
import ImageInput from "../ImageInput";
import { t } from "i18next";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { stockService } from "../../Api/Services/StockService";
import { StockModel } from "../../Api/Models/stock.model";
import ExchangeTshirts from "./ExchangeTshirts";
import {
  KitModel,
  KitProductModel,
  ProductModel,
} from "../../Api/Models/product.model";
import ExchangeMissing from "./ExchangeMissing";
import ProductState from "./ProductState";
import { ReturnCondition } from "../../Api/Models/return.model";

interface ExchangeProps {
  gears: RiderProductModel[];
  rider: RiderModel;
  updateGears: CallableFunction;
  missingKitProducts: KitModel | null;
  fetchMissingKitProducts: CallableFunction;
}

export interface ExceptionItem {
  gearId: number;
  variationId: number | null;
  condition: ReturnCondition;
  image: File;
  note: string | null;
}

export interface MultiExchangeItem {
  gearId: number;
  variationId: number | null;
  groupSelectedProductId?: null | number;
  groupSelectedProductVariationId?: null | number;
}

export interface TshirtItem {
  productId: number;
  variationId: number;
  quantity: number;
}

const Exchange: React.FunctionComponent<ExchangeProps> = ({
  gears,
  rider,
  updateGears,
  missingKitProducts,
  fetchMissingKitProducts,
}) => {
  const { startLoading, finnishLoading } = useLoading();
  const [images, setImages] = useState<File[]>([]);
  const { showInfo } = useInfoModal();
  const [stocks, setStocks] = useState<StockModel[]>([]);

  //const navigate = useNavigate();
  const { setSubTitle, subTitle } =
    useOutletContext<RiderProfileLayoutContextInterface>();
  const [maxTshirtsCount, setMaxTshirtsCount] = useState<{
    maxTshirts: number;
    returnModifier: number;
  }>({ maxTshirts: 0, returnModifier: 0 });
  const [selectedGears, setSelectedGears] = useState<MultiExchangeItem[]>([]);
  const [returnedTshirts, setReturnedTshirts] = useState<TshirtItem[]>([]);
  const [dispatchedTshirts, setDispatchedTshirts] = useState<TshirtItem[]>([]);
  const [missingProducts, setMissingProducts] = useState<KitProductModel[]>(
    missingKitProducts?.products.filter((item: KitProductModel) => {
        return !gears.some((gear) => {
            return gear.replacementProduct?.id === item.productId;
        });
    }) ?? []
  );
  const [selectedExceptionGearId, setSelectedExceptionGearId] = useState<
    number | null
  >(null);
  const [tShirts, setTShirts] = useState<ProductModel[]>([]);

  const [exceptions, setExceptions] = useState<ExceptionItem[]>([]);
  function emptyFunction() {}

  useEffect(() => {
    let currnetSubTitle = subTitle;
    setSubTitle("");
    fetchStocks();
    fetchMaxTshirts();
    fetchTshirts();
    return () => {
      setSubTitle(currnetSubTitle);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (missingKitProducts?.products) {
      setMissingProducts(missingKitProducts?.products.filter((item: KitProductModel) => {
        return !gears.some((gear) => {
          return gear.replacementProduct?.id === item.productId;
        });
      }));
    }
  }, [missingKitProducts?.products, gears]);

  const fetchStocks = () => {
    startLoading("get-stock-list");
    stockService
      .listStocks()
      .then((stocks) => {
        setStocks(stocks);
      })
      .finally(() => {
        finnishLoading("get-stock-list");
      });
  };

  const fetchMaxTshirts = () => {
    riderService.getAssignTshirtsCount(rider.riderId).then((response) => {
      setMaxTshirtsCount(response);
    });
  };

  const fetchTshirts = () => {
    startLoading("exchange-fetch-tshirts");
    riderService
      .listTshirts(rider.riderId)
      .then((products) => {
        setTShirts(
          products.map((item) => {
            item.selectedVariationId = item.variations[0].id;
            return item;
          })
        );
      })
      .finally(() => {
        finnishLoading("exchange-fetch-tshirts");
      });
  };

  const stockAvailable = (
    gear: RiderProductModel,
    exchangeProductId?: number | null
  ) => {
    let productStocks: StockModel[] = [];

    if (
      exchangeProductId &&
      gear.groupProducts?.find((x) => x.id === exchangeProductId)
    ) {
      productStocks = stocks.filter((x) => x.productId === exchangeProductId);
      let groupProduct = gear.groupProducts.find(
        (x) => x.id === exchangeProductId
      );

      if ((groupProduct?.variations.length ?? 0) > 0) {
        let stockAvailable = false;
        groupProduct?.variations.forEach((variation) => {
          if (
            (productStocks.find((x) => x.variationId === variation.id)
              ?.quantity ?? -1) > 0
          ) {
            stockAvailable = true;
          }
        });

        return stockAvailable;
      } else if (productStocks.length === 1) {
        return productStocks[0].quantity > 0;
      }
    }

    if (gear.replacementProduct) {
      productStocks = stocks.filter(
        (x) => x.productId === gear.replacementProduct?.id
      );

      if (gear.replacementProduct.variations.length > 0) {
        let stockAvailable = false;

        gear.replacementProduct.variations.forEach((variation) => {
          if (
            (productStocks.find(
              (x) =>
                x.productId === gear.replacementProduct?.id &&
                x.variationId === variation.id
            )?.quantity ?? -1) > 0
          ) {
            stockAvailable = true;
          }
        });
        return stockAvailable;
      }

      return (
        (productStocks.find(
          (x) =>
            x.productId === gear.replacementProduct?.id &&
            x.variationId === null
        )?.quantity ?? -1) > 0
      );
    }

    productStocks = stocks.filter((x) => x.productId === gear.productId);

    if (gear.availableVariations.length > 0) {
      let stockAvailable = false;
      gear.availableVariations.forEach((variation) => {
        if (
          (productStocks.find((x) => x.variationId === variation.id)
            ?.quantity ?? -1) > 0
        ) {
          stockAvailable = true;
        }
      });

      return stockAvailable;
    }

    return (
      (productStocks.find((x) => x.variationId === null)?.quantity ?? -1) > 0
    );
  };

  const exchangeMultiple = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      selectedGears.length < 1 &&
      returnedTshirts.length < 1 &&
      dispatchedTshirts.length < 1 &&
      missingProducts.filter((x) => x.checked).length < 1 &&
      exceptions.length < 1
    ) {
      return;
    }

    if (
      (selectedGears.some((x) =>
        gears
          .filter((y) => y.exchangeList !== "size_change")
          .some((y) => y.id === x.gearId)
      ) ||
        returnedTshirts.length > 0 ||
        dispatchedTshirts.length > 0 ||
        missingProducts.filter((x) => x.checked).length > 0 ||
        exceptions.length > 0) &&
      images.length < 1
    ) {
      return;
    }

    if (
      selectedGears.some((x) => x.variationId === -1) ||
      exceptions.some((x) => x.variationId === -1)
    ) {
      showInfo(t("Please select a size for all exchange products!"));
      return;
    }

    startLoading("profile-multiple-exchange");
    riderService
      .multiExchange(
        rider.riderId,
        selectedGears,
        returnedTshirts,
        dispatchedTshirts,
        missingProducts.filter((x) => x.checked),
        exceptions,
        images[0]
      )
      .then(() => {
        setSelectedGears([]);
        setReturnedTshirts([]);
        setDispatchedTshirts([]);
        setImages([]);
        updateGears();
        fetchStocks();
        setMissingProducts([]);
        fetchMissingKitProducts();
        setExceptions([]);
        setMaxTshirtsCount({ maxTshirts: 0, returnModifier: 0 });
        fetchMaxTshirts();
        showInfo(t("Items successfully exchanged!"));
      })
      .catch(() => {
        showInfo(t("Items exchange was unsuccessfull!"));
      })
      .finally(() => {
        finnishLoading("profile-multiple-exchange");
      });
  };

  const getExchangeListItem = (gear: RiderProductModel) => {
    let imgSrc = "";
    let content = null;

    let selectedGear = selectedGears.find((x) => x.gearId === gear.id);

    if (selectedGear && selectedGear?.groupSelectedProductId === -1) {
      imgSrc = "";
      content = (
        <p>
          <strong>
            <select
              className={"text-blue"}
              onChange={(e) => {
                setSelectedGears((prev) => {
                  return prev.map((x) =>
                    x.gearId === gear.id
                      ? {
                          ...x,
                          groupSelectedProductId: parseInt(e.target.value),
                        }
                      : x
                  );
                });
              }}
            >
              <option>- Please Select -</option>
              {gear?.groupProducts
                ?.filter((x) => stockAvailable(gear, x.id))
                ?.map((groupProduct) => (
                  <option key={groupProduct.id} value={groupProduct.id}>
                    {groupProduct.name}
                  </option>
                ))}
            </select>
          </strong>
        </p>
      );
    } else if (
      selectedGear &&
      (selectedGear?.groupSelectedProductId ?? 0) > 0
    ) {
      let newProduct = gear.groupProducts?.find(
        (x) => x.id === selectedGear?.groupSelectedProductId
      );
      imgSrc =
        gear.groupProducts?.find(
          (x) => x.id === selectedGear?.groupSelectedProductId
        )?.image ?? "";
      if (!newProduct) {
        content = null;
      } else {
        content = (
          <p>
            <strong>
              {newProduct.name}

              {newProduct.variations?.length > 0 ? (
                <>
                  <span className="text-blue">&nbsp;-&nbsp;</span>
                  <select
                    className={"text-blue"}
                    defaultValue={-1}
                    onChange={(e) => {
                      setSelectedGears((prev) => {
                        return prev.map((g) =>
                          g.gearId === gear.id
                            ? {
                                ...g,
                                groupSelectedProductVariationId: parseInt(
                                  e.target.value
                                ),
                              }
                            : g
                        );
                      });
                    }}
                  >
                    <option value={-1} disabled={true}>
                      -
                    </option>

                    {newProduct.variations
                      .filter(
                        (x) =>
                          (stocks.find((stock) => stock.variationId === x.id)
                            ?.quantity ?? -1) > 0
                      )
                      .map((x) => (
                        <option value={x.id} key={x.id}>
                          {x.name}
                        </option>
                      ))}
                  </select>
                </>
              ) : null}
            </strong>
            {!stockAvailable(gear, selectedGear.groupSelectedProductId) ? (
              <>
                <br />
                <span className="text-red">{t("Out of stock")}</span>
              </>
            ) : null}
          </p>
        );
      }
    } else {
      imgSrc = gear.productImage;
      content = (
        <p>
          <strong>
            {gear.productName}

            {gear.replacementProduct ? (
              <span className="text-blue">
                &nbsp;Replace&nbsp;{">>"}&nbsp;
                {gear.replacementProduct.name}
              </span>
            ) : null}

            {gear.productVariationId ||
            (gear.replacementProduct &&
              gear.replacementProduct?.variations?.length > 0) ? (
              <>
                <span className="text-blue">&nbsp;-&nbsp;</span>
                <select
                  className={
                    !selectedGears.find((x) => x.gearId === gear.id)
                      ? "text-gray"
                      : "text-blue"
                  }
                  defaultValue={-1}
                  disabled={!selectedGears.find((x) => x.gearId === gear.id)}
                  onChange={(e) => {
                    let state = [...selectedGears];
                    state = state.map((g) =>
                      g.gearId === gear.id
                        ? {
                            gearId: g.gearId,
                            variationId: parseInt(e.target.value),
                          }
                        : g
                    );
                    setSelectedGears(state);
                  }}
                >
                  <option
                    value={-1}
                    disabled={!!selectedGears.find((x) => x.gearId === gear.id)}
                  >
                    -
                  </option>

                  {(gear.replacementProduct?.variations
                    ? gear.replacementProduct?.variations ?? []
                    : gear.availableVariations
                  )
                    .filter(
                      (x) =>
                        (stocks.find((stock) => stock.variationId === x.id)
                          ?.quantity ?? -1) > 0
                    )
                    .map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                </select>
              </>
            ) : null}
          </strong>
          {!stockAvailable(gear) ? (
            <>
              <br />
              <span className="text-red">{t("Out of stock")}</span>
            </>
          ) : null}
        </p>
      );
    }

    return (
      <div className="exchange-list__item" key={gear.id}>
        <div className="gap-4px nav-card mb-4">
          {imgSrc.length > 0 ? <img src={imgSrc} alt="package name" /> : null}
          {content}
          {gear.groupProducts ? (
            <div
              style={{ display: "flex", flexGrow: 1, justifyContent: "end" }}
            >
              <svg
                fill="none"
                stroke={
                  selectedGears.find((x) => x.gearId === gear.id)
                    ? "gray"
                    : "blue"
                }
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="21px"
                onClick={() => {
                  selectedGears.find((x) => x.gearId === gear.id)
                    ? emptyFunction()
                    : setSelectedGears([
                        ...selectedGears,
                        {
                          gearId: gear.id,
                          variationId: gear.productVariationId,
                          groupSelectedProductId: -1,
                        },
                      ]);
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          ) : null}
        </div>
        <span>
          <div className={"status open"}>
            {(gear.exchangedDisabled && !gear.replacementProduct) ||
            !stockAvailable(gear, selectedGear?.groupSelectedProductId) ? (
              <img src={exchangeIcon} alt="Exchange img" className="disabled" />
            ) : (
              <button
                type="button"
                className={
                  selectedGears.find((x) => x.gearId === gear.id)
                    ? "button-product-status-returned"
                    : "button-product-status-default"
                }
                onClick={() => {
                  selectedGears.find((x) => x.gearId === gear.id)
                    ? setSelectedGears([
                        ...selectedGears.filter((x) => x.gearId !== gear.id),
                      ])
                    : setSelectedGears([
                        ...selectedGears,
                        {
                          gearId: gear.id,
                          variationId:
                            gear.productVariationId != null ? -1 : null,
                        },
                      ]);
                }}
              >
                <i
                  className={
                    "icon-approve " +
                    (!selectedGears.find((x) => x.gearId === gear.id)
                      ? "text-green"
                      : "")
                  }
                ></i>
              </button>
            )}
          </div>
        </span>
      </div>
    );
  };

  const addExceptionData = (
    condition: ReturnCondition,
    image: File,
    note: string | null
  ) => {
    if (!selectedExceptionGearId) {
      return;
    }

    setExceptions((prev) => [
      ...prev,
      {
        gearId: selectedExceptionGearId,
        variationId:
          (gears.find((x) => x.id === selectedExceptionGearId)
            ?.availableVariations?.length ?? 0) > 0
            ? -1
            : null,
        condition: condition,
        image: image,
        note: note,
      },
    ]);
    setSelectedExceptionGearId(null);
  };

  if (stocks.length === 0) {
    return null;
  }

  if (selectedExceptionGearId) {
    return (
      <ProductState
        saveButtonOnClick={addExceptionData}
        backButtonOnClick={() => setSelectedExceptionGearId(null)}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4px">
      <form onSubmit={exchangeMultiple}>
        {gears.some((x) => x.exchangeList === "eligible") ? (
          <h1 className="text-white text-center text-large">
            {t("Eligible exchange")}
          </h1>
        ) : null}

        {gears
          .filter((x) => x.exchangeList === "eligible" && !x.isTshirt)
          .map((gear) => {
            return getExchangeListItem(gear);
          })}
        <ExchangeTshirts
          dispatchedTshirts={dispatchedTshirts}
          returnedTshirts={returnedTshirts}
          gears={gears}
          setDispatchedTshirts={setDispatchedTshirts}
          setReturnedTshirts={setReturnedTshirts}
          stocks={stocks}
          maxTshirtsCount={maxTshirtsCount}
          tShirts={tShirts}
          setTShirts={setTShirts}
        />

        {gears.some((x) => x.exchangeList === "accident") ? (
          <h1 className="text-white text-center text-large mt-4">
            {t("Accidents")}
          </h1>
        ) : null}

        {gears
          .filter((x) => x.exchangeList === "accident")
          .map((gear) => {
            return getExchangeListItem(gear);
          })}

        {missingProducts.length > 0 ? (
          <>
            <h1 className="text-white text-center text-large mt-4">
              {t("Missing Products")}
            </h1>
            <ExchangeMissing
              missingProducts={missingProducts}
              setMissingProducts={setMissingProducts}
              stocks={stocks}
            />
          </>
        ) : null}

        {gears.some((x) => x.exchangeList === "size_change") ? (
          <h1 className="text-white text-center text-large mt-4">
            {t("Size changes")}
          </h1>
        ) : null}
        {gears
          .filter((x) => x.exchangeList === "size_change")
          .map((gear) => {
            return getExchangeListItem(gear);
          })}

        {gears.some((x) => x.exchangeList === "exception") ? (
          <h1 className="text-white text-center text-large mt-4">
            {t("Exceptions")}
          </h1>
        ) : null}

        {gears
          .filter((x) => x.exchangeList === "exception")
          .map((gear) => (
            <div className="exchange-list__item" key={gear.id}>
              <div className="gap-4px nav-card mb-4">
                <img src={gear.productImage} alt="package name" />
                <p>{gear.productName}</p>
                <select
                  className={
                    exceptions.find((x) => x.gearId === gear.id)
                      ? "text-blue"
                      : "text-gray"
                  }
                  onChange={(e) => {
                    let exceptionState = [...exceptions];
                    exceptionState = exceptionState.map((item) => {
                      if (item.gearId === gear.id) {
                        item.variationId = parseInt(e.target.value);
                      }
                      return item;
                    });

                    setExceptions(exceptionState);
                  }}
                  value={
                    exceptions.find((x) => x.gearId === gear.id)?.variationId ??
                    -1
                  }
                  disabled={!exceptions.find((x) => x.gearId === gear.id)}
                >
                  <option value={-1} disabled={true}>
                    -
                  </option>

                  {gear.availableVariations
                    .filter(
                      (x) =>
                        (stocks.find((stock) => stock.variationId === x.id)
                          ?.quantity ?? -1) > 0
                    )
                    .map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                </select>
              </div>
              <span
                onClick={() => {
                  if (!gear.exchangedDisabled && !gear.discontinued) {
                    setSelectedExceptionGearId(gear.id);
                  }
                }}
              >
                <div
                  className={
                    "status " +
                    (exceptions.find((x) => x.gearId === gear.id)
                      ? "closed"
                      : "open")
                  }
                >
                  {exceptions.find((x) => x.gearId === gear.id) ? (
                    <img src={checkIcon} alt="Success icon" />
                  ) : (
                    <img
                      src={exchangeIcon}
                      alt="Exchange img"
                      className={gear.exchangedDisabled ? "disabled" : ""}
                    />
                  )}
                </div>
              </span>
            </div>
          ))}

        {selectedGears.length > 0 ||
        returnedTshirts.length > 0 ||
        dispatchedTshirts.length > 0 ||
        missingProducts.filter((x) => x.checked).length > 0 ||
        exceptions.length > 0 ? (
          <>
            <ImageInput
              images={images}
              setImages={setImages}
              required={
                selectedGears.some((x) =>
                  gears
                    .filter((y) => y.exchangeList !== "size_change")
                    .some((y) => y.id === x.gearId)
                ) ||
                returnedTshirts.length > 0 ||
                dispatchedTshirts.length > 0 ||
                missingProducts.filter((x) => x.checked).length > 0 ||
                exceptions.length > 0
              }
            />
            <button
              type="submit"
              className="button button--small font-12 py-2 px-6 button--green mb-8 w-full"
            >
              {t("Exchange")}
            </button>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default Exchange;
